import { Button as AntButton } from 'antd';
import styled from 'styled-components';

export const Button = styled(AntButton)`
  &.ant-btn {
  }

  &.ant-btn-default {
    &.ant-btn-default:hover {
      color: black;
      border-color: blue;
    }
  }
`;

export const PrimaryButton = styled(Button)`
  background: #e1b248;

  &.ant-btn-default {
    &.ant-btn-default:hover {
      color: black;
      border-color: blue;
    }
  }
`;
