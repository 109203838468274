import { CreatePost } from 'containers';
import { MainLayout, ScLabel, StepsCommon } from 'components';
import { useState } from 'react';
import styled from 'styled-components';
import { Button, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

export const ScSuccessWrapper = styled.div`
  margin-top: 116px;
  display: flex;
  width: 590px;
  padding: 40px 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.24);

  background: #1d1c1f;
`;

export const CreatePostPage = () => {
    const navigate = useNavigate()
    const [currentActive, setCurrentActive] = useState(0);
    const [finished, setFinished] = useState<boolean>(false);

    return (
        <MainLayout>
            {!finished && (
                <ScLabel
                    color="#FFFFFF"
                    mt={61}
                    fs={28}
                    fw={600}
                    lh={150}
                    letterSpacing={-0.84}
                    mb={24}
                >
                    Create post
                </ScLabel>
            )}
            {!finished && <StepsCommon current={currentActive} />}
            {finished ? (
                <ScSuccessWrapper>
                    <img src="/icons/success.png" alt="" />
                    <ScLabel
                        color="#FFFFFF"
                        fs={28}
                        fw={600}
                        lh={150}
                        letterSpacing={-0.84}
                        mt={24}
                    >
                        Publish Success
                    </ScLabel>
                    <ScLabel
                        color="#FFFFFF"
                        fs={15}
                        fw={600}
                        lh={150}
                        letterSpacing={-0.45}
                    >
                        Your post is successfully upload.
                    </ScLabel>
                    <Row>
                        <Button type='link'>View my Post</Button>
                        <Button type='link' onClick={() => navigate('/')}>Go to Home</Button>
                    </Row>
                </ScSuccessWrapper>
            ) : (
                <CreatePost setActiveStep={setCurrentActive} setFinished={setFinished} />
            )}
        </MainLayout>
    );
};
