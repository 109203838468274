import styled from 'styled-components';

import { Container } from './Grid';

const ScMain = styled.footer`
  font-size: 16px;
  color: #d6d7dc;
  background: #0b0b0f;
  height: 100px;
  padding-bottom: 48px;
  padding-top: 48px;
  margin-top: 48px;

  position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

  a {
    color: #d6d7dc;

    &:active {
      color: #d6d7dc;
    }
  }
`;

const ScContent = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`;

export const Footer = () => {
  return (
    <ScMain>
      <Container>
        <ScContent>
          <div>@2023 Gigglr.xyz</div>
        </ScContent>
      </Container>
    </ScMain>
  );
};
