import { PublicKey } from '@solana/web3.js';
import { Program, AnchorProvider } from '@coral-xyz/anchor';
import { CONNECTION, PROGRAM_ADDRESS } from 'consts';
import type { GigglrXyz } from 'programs/types/gigglr-xyz';

export type AnchorWallet = {
  publicKey: PublicKey;
  signAllTransactions: any;
  signTransaction: any;
};

const getConnectionProvider = async (wallet: AnchorWallet) => {
  return new AnchorProvider(
    CONNECTION,
    wallet,
    AnchorProvider.defaultOptions()
  );
};

export const getProgram = async (wallet: AnchorWallet) => {
  // Get a connection
  const provider = await getConnectionProvider(wallet);

  // Get metadata about your solana program
  const idl = await Program.fetchIdl(PROGRAM_ADDRESS, provider);
  if (!idl) return;

  // Create a program that you can call
  return new Program(
    idl,
    PROGRAM_ADDRESS,
    provider
  ) as unknown as Program<GigglrXyz>;
};
