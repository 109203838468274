import { Program } from '@coral-xyz/anchor';
import { useWallet } from '@solana/wallet-adapter-react';
import { GigglrXyz } from 'programs/types/gigglr-xyz';
import { useEffect, useState } from 'react';
import { AnchorWallet, getProgram } from 'utils';

export const useProgram = () => {
  const wallet = useWallet();
  const [program, setProgram] = useState<Program<GigglrXyz>>();

  useEffect(() => {
    const getAsyncProgram = async () => {
      if (!wallet || !wallet.publicKey) return;
      const anchorWallet: AnchorWallet = {
        publicKey: wallet.publicKey,
        signTransaction: wallet.signTransaction,
        signAllTransactions: wallet.signAllTransactions,
      };
      const program: any = await getProgram(anchorWallet);
      setProgram(program);
    };

    getAsyncProgram();
  }, [wallet]);

  return {
    program,
  };
};
