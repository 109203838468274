import styled from 'styled-components';

export const Container = styled.div`
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: auto;
  margin-right: auto;
`;

export const ScMain = styled.div`
  margin-top: 3rem;
  margin-bottom: 5rem;

  @media screen and (min-width: 960px) {
    margin-top: 3rem;
    margin-bottom: 10rem;
  }
`;

export const ScSection = styled.section`
  margin-top: 3rem;
  margin-bottom: 5rem;

  @media screen and (min-width: 960px) {
    margin-top: 3rem;
    margin-bottom: 10rem;
  }
`;
