import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from './Grid';
import { useProfile } from 'hooks';
import { Avatar, Button } from 'antd';

const ScHeader = styled.header`
  font-family: 'Inter', sans-serif;
  background: #1d1c1f;
  border-bottom: 1px solid #303239;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  z-index: 98;
`;

const ScNavBar = styled.div`
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ScNavBarLeft = styled.div``;
const ScNavBarRight = styled.div`
  display: flex;
`;

const ScLogo = styled(Link)`
  display: flex;
  flex: none;
  align-items: center;
  font-weight: bold;
  margin-right: 24px;

  img {
    height: 32px;
    margin-right: 4px;
  }

  span {
    font-size: 24px;
    margin-right: 8px;
    font-weight: bold;
  }

  &:hover {
    text-decoration: none;
    color: white;
  }
`;

const ScButton = styled(Button)`
  display: flex;
  padding: 10px 16px 11px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #1f57e7;
  border: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.45px;
  margin-right: 8px;

  > div > img {
    margin-right: 8px;
  }
`;

export const NavBar = () => {
  const navigate = useNavigate();
  const { profile, avatar } = useProfile();

  return (
    <>
      <ScHeader>
        <Container>
          <ScNavBar>
            <ScNavBarLeft>
              <ScLogo to="/">
                <img
                  src="/images/frog_logo.png"
                  loading="eager"
                  alt="Gigglr Logo"
                />
                GIGglr.xyz
              </ScLogo>
            </ScNavBarLeft>
            <ScNavBarRight>
              {profile ? (
                <>
                  <ScButton title="" onClick={() => navigate('/create-post')}>
                    <img src="/icons/pen.png" alt="Gigglr Logo" />
                    Post
                  </ScButton>
                  {avatar ? (
                    <Avatar src={avatar} />
                  ) : (
                    <Avatar src={null}>{profile?.name}</Avatar>
                  )}
                </>
              ) : (
                <WalletMultiButton />
              )}
            </ScNavBarRight>
          </ScNavBar>
        </Container>
      </ScHeader>
    </>
  );
};
