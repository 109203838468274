import { Connection, PublicKey } from '@solana/web3.js';

export const CONNECTION_OPTIONS = {
  preflightCommitment: 'processed',
};

export const PROGRAM_ADDRESS = new PublicKey(
  '9oMPbwfYUFLVqm22qtnTT2jCURLeohmat57YKQWMwMCY'
);

export const XSB_MINT_ADDRESS = new PublicKey(
  'GjAXQMHb8zYA3CA8hBsbKW1cELybunzydpXn5yvem8xK'
);

export const NFT_STORAGE_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDZEZTE4QmYxMTE1YTc0NEY0MUU1QUY5NDc4NWFlODNBNGM0YkIwMjQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTcwMDU0MTUzNjg2MSwibmFtZSI6ImdpZ2dsci54eXoifQ.MK6UGO2iVwcQkd1XlZYi02f_9hkp4a1D9kzAyMBz6NA';

export const CONNECTION = new Connection(
  'https://rpc.ankr.com/solana_devnet/9b5f1c65250aec76490ae2deb79e804479c44e92f7c786201ff987974de23676',
  {
    wsEndpoint:
      'wss://rpc.ankr.com/solana_devnet/ws/9b5f1c65250aec76490ae2deb79e804479c44e92f7c786201ff987974de23676',
  }
  // 'wss://rpc.ankr.com/solana_devnet/ws/9b5f1c65250aec76490ae2deb79e804479c44e92f7c786201ff987974de23676'
  // 'https://rpc.ankr.com/solana/9b5f1c65250aec76490ae2deb79e804479c44e92f7c786201ff987974de23676'
);
