// import { z } from 'zod';

// const MAX_FILE_SIZE = 500000;
// const ACCEPTED_IMAGE_TYPES = [
//   'image/jpeg',
//   'image/jpg',
//   'image/png',
//   'image/webp',
// ];

// const profileSchema = z.object({
//   userName: z
//     .string()
//     .min(2, { message: 'Name should have at least 2 letters' })
//     .max(20, { message: 'Name should  have at most 20 characters' }),
//   displayName: z
//     .string()
//     .min(2, { message: 'Name should have at least 2 letters' })
//     .max(20, { message: 'Name should  have at most 20 characters' }),

//   // file: z
//   //   .any()
//   //   .refine((files) => files?.length === 1, 'Image is required.')
//   //   .refine(
//   //     (files) => files?.[0]?.size <= MAX_FILE_SIZE,
//   //     `Max file size is 5MB.`
//   //   )
//   //   .refine(
//   //     (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
//   //     '.jpg, .jpeg, .png and .webp files are accepted.'
//   //   ),
// });

// const validateImage = (files: any, cb: (error: string) => void) => {
//   if (files?.size <= MAX_FILE_SIZE) {
//     console.log('🚀 ~ validateImage ~ files:', files);
//     setError('Max file size is 5MB');
//     return false;
//   } else if (ACCEPTED_IMAGE_TYPES.includes(files?.type)) {
//     setError('.jpg, .jpeg, .png and .webp files are accepted.');
//     return false;
//   } else {
//     setError('');
//     return true;
//   }
// };

// export { profileSchema, validateImage };

import * as Yup from 'yup';

const MAX_FILE_SIZE = 5000000;
const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
];

const profileSchema = Yup.object().shape({
  userName: Yup.string()
    .min(2, 'Name should have at least 2 letters')
    .max(20, 'Name should  have at most 20 characters')
    .required('User name is required'),
  displayName: Yup.string()
    .min(2, 'Name should have at least 2 letters')
    .max(20, 'Name should  have at most 20 characters')
    .required('Display name is required'),
});

const postSchema = Yup.object({
  title: Yup.string()
    .min(2, 'Title should have at least 2 letters')
    .max(280, 'Title should have at most 280 characters')
    .required(),
});

const validateImage = (files: any, callback: (error: string) => void) => {
  let valid: boolean = true;
  console.log('🚀 ~ validateImage ~ files?.size:', files?.size);
  if (files?.size >= MAX_FILE_SIZE) {
    callback('Max file size is 5MB');
    valid = false;
  }
  if (!ACCEPTED_IMAGE_TYPES.includes(files?.type)) {
    callback('.jpg, .jpeg, .png and .webp files are accepted.');
    valid = false;
  }
  console.log('🚀 ~ validateImage ~ valid:', valid);

  return valid;
};

export { profileSchema, postSchema, validateImage };
