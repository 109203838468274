import { web3 } from '@coral-xyz/anchor';
import { useWallet } from '@solana/wallet-adapter-react';
import { PROGRAM_ADDRESS } from 'consts';
import { useEffect, useState } from 'react';
import { metaplex } from './useNFTStorage';
import { useProgram } from './useProgram';

export const useProfile = () => {
  const wallet = useWallet();
  const { program } = useProgram();
  const [profile, setProfile] = useState<any>();
  const [avatar, setAvatar] = useState<string>('');

  useEffect(() => {
    const getProfile = async () => {
      try {
        if (!wallet || !wallet.publicKey) return;
        const [userProfileAddress] = web3.PublicKey.findProgramAddressSync(
          [wallet?.publicKey?.toBuffer()],
          PROGRAM_ADDRESS
        );
        const p = await program?.account.profile.fetchNullable(
          userProfileAddress
        );
        setProfile(p);
        if (p?.nftToken) {
          const nft = await metaplex.nfts().findByToken({ token: p?.nftToken });
          console.log('🚀 ~ getProfile ~ nft:', nft);
          if (nft) {
            console.log('nft', nft);
            setAvatar(nft.json?.image || '');
          }
        }
      } catch (error) {
        console.log('🚀 ~ getProfile ~ error:', error);
      }
    };
    getProfile();
  }, [program?.account.profile, wallet]);

  return {
    profile,
    avatar,
  };
};
