export interface IFormProfile {
  displayName: string;
  userName: string;
  file: File | null;
}

export interface IFormPost {
  title: string;
}

export interface ICategory {
  id: number;
  name: string;
  icon?: string;
}

export enum EnumInterest {
  UNMARK = 'unmark',
  MARK = 'mark',
}
