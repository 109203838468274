import { useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { walletAdapterIdentity } from '@metaplex-foundation/js';
import { IFormPost, postSchema } from 'utils';
import { web3 } from '@coral-xyz/anchor';
import * as anchor from '@coral-xyz/anchor';
import { ASSOCIATED_PROGRAM_ID } from '@coral-xyz/anchor/dist/cjs/utils/token';
import { PROGRAM_ADDRESS, XSB_MINT_ADDRESS } from 'consts';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Keypair } from '@solana/web3.js';
import { metaplex, useNFTStorage, useProgram } from 'hooks';
import {
    FileType,
    FormikInput,
    MainWrapper,
    ScBtnSubmit,
    ScLabel,
    getBase64,
} from 'components';
import { Form, Formik } from 'formik';
import Dragger from 'antd/es/upload/Dragger';
import styled from 'styled-components';

const DragWrapper = styled(Dragger)`
  display: flex;
  padding: 40px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  background: #0f0e13;
  .ant-upload-drag {
    border: none;
  }
  margin-bottom: 32px;
  .ant-upload-list-text {
    display: none;
  }
`;

const CenterHorizontal = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CreatePost = ({
    setActiveStep,
    setFinished
}: {
    setActiveStep: (step: number) => void,
    setFinished: (finished: boolean) => void,
}) => {
    const { uploadNft } = useNFTStorage();
    const { program } = useProgram();
    const wallet = useWallet();
    const [filePreview, setFilePreview] = useState<any>();
    const [fileUpload, setFileUpload] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!wallet.publicKey) {
            return;
        }
        metaplex.use(walletAdapterIdentity(wallet));
    }, [wallet]);


    const createPost = async (
        values: IFormPost,
        setSubmitting: any
    ) => {
        setSubmitting(true);
        setLoading(true)
        try {

            if (!wallet.publicKey) {
                return;
            }

            const nftTx: any = await uploadNft(fileUpload, values?.title);

            if (nftTx) {
                setActiveStep(2);
            }

            const [settingAddress] = web3.PublicKey.findProgramAddressSync(
                [anchor.utils.bytes.utf8.encode('SETTING')],
                PROGRAM_ADDRESS
            );

            const [programXsbTokenAccount] = web3.PublicKey.findProgramAddressSync(
                [
                    settingAddress.toBuffer(),
                    TOKEN_PROGRAM_ID.toBuffer(),
                    XSB_MINT_ADDRESS.toBuffer(),
                ],
                ASSOCIATED_PROGRAM_ID
            );

            const [userXsbTokenAccount] = web3.PublicKey.findProgramAddressSync(
                [
                    wallet?.publicKey?.toBuffer(),
                    TOKEN_PROGRAM_ID.toBuffer(),
                    XSB_MINT_ADDRESS.toBuffer(),
                ],
                ASSOCIATED_PROGRAM_ID
            );
            const [userProfileAddress] = web3.PublicKey.findProgramAddressSync(
                [wallet?.publicKey?.toBuffer()],
                PROGRAM_ADDRESS
            );

            const userPostAccount = Keypair.generate();

            const postTx = await program?.methods
                .createPost()
                .accounts({
                    setting: settingAddress,
                    programTokenAccount: programXsbTokenAccount,
                    user: wallet.publicKey,
                    userTokenAccount: userXsbTokenAccount,
                    profile: userProfileAddress,
                    systemProgram: anchor.web3.SystemProgram.programId,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    post: userPostAccount.publicKey,
                    nftPostToken: nftTx.tokenAddress,
                    nftPostMetadata: nftTx.metadataAddress,
                })
                .signers([userPostAccount])
                .rpc();
            console.log("🚀 ~ postTx:", postTx)

            setSubmitting(false);
            setLoading(false);
            if (postTx) setFinished(true)
        } catch (error) {
            setSubmitting(false);
            setLoading(false);
        }
    };

    return (
        <MainWrapper mt={24}>
            <Formik
                validationSchema={postSchema}
                onSubmit={(values, { setSubmitting }) => {
                    createPost(values, setSubmitting);
                }}
                initialValues={{
                    title: '',
                }}
            >
                {({ isSubmitting, values }) => (
                    <Form style={{ width: '100%' }}>
                        <FormikInput
                            name="title"
                            placeholder="Write something fun"
                            title="Display name"
                            showCountLength
                        />
                        <DragWrapper
                            multiple={false}
                            onChange={async ({ file }: any) => {
                                setFileUpload(file)
                                if (!file?.url || file?.preview) {
                                    const previewImage = await getBase64(
                                        file.originFileObj as FileType
                                    );
                                    setFilePreview(previewImage);
                                    setActiveStep(1);
                                }
                            }}
                        >
                            {filePreview ? (
                                <img src={filePreview} alt="" style={{ width: '100%' }} />
                            ) : (
                                <>
                                    <img src="/icons/gallery.png" alt="" />
                                    <CenterHorizontal>
                                        <ScLabel
                                            mt={24}
                                            mb={24}
                                            color="rgba(255, 255, 255, 0.52)"
                                            fs={13}
                                            fw={500}
                                            lh={150}
                                            letterSpacing={-0.39}
                                            style={{ width: 299 }}
                                        >
                                            Select an image / video or to upload. You can drag & drop
                                            a file here
                                        </ScLabel>
                                    </CenterHorizontal>
                                    <CenterHorizontal>
                                        <ScBtnSubmit bgColor="#1F57E7" wAuto>
                                            <ScLabel
                                                color="#FFFFFF"
                                                fs={15}
                                                fw={500}
                                                lh={150}
                                                letterSpacing={-0.45}
                                            >
                                                Select file
                                            </ScLabel>
                                        </ScBtnSubmit>
                                    </CenterHorizontal>
                                </>
                            )}
                        </DragWrapper>
                        <ScBtnSubmit
                            type="primary"
                            disabled={!filePreview || isSubmitting}
                            htmlType="submit"
                            loading={loading || isSubmitting}
                        >
                            <ScLabel
                                color={'#FFFFFF'}
                                fs={15}
                                fw={500}
                                lh={150}
                                letterSpacing={-0.45}
                            >
                                {!filePreview ? 'Approve as NFT' : 'Create post'}
                            </ScLabel>
                        </ScBtnSubmit>
                    </Form>
                )}
            </Formik>
        </MainWrapper>
    );
};
