import { Button, Col, Row } from 'antd';
import styled, { css } from 'styled-components';

export const ScLabel = styled.div<{
  fw?: number;
  pl?: number;
  pr?: number;
  pt?: number;
  pb?: number;
  ml?: number;
  mr?: number;
  mt?: number;
  mb?: number;
  fs?: number;
  fstyle?: string;
  color?: string;
  lh?: number;
  textAlign?: string;
  letterSpacing?: number;
  fontFamily?: string;
}>`
  padding-left: ${(p) => p.pl || 0}px;
  padding-right: ${(p) => p.pr || 0}px;
  padding-bottom: ${(p) => p.pb || 0}px;
  padding-top: ${(p) => p.pt || 0}px;
  margin-top: ${(p) => p.mt || 0}px;
  margin-bottom: ${(p) => p.mb || 0}px;
  margin-left: ${(p) => p.ml || 0}px;
  margin-right: ${(p) => p.mr || 0}px;
  color: ${(p) => p.color || 'rgba(255, 255, 255, 0.52)'};
  font-size: ${(p) => p.fs || 13}px;
  font-style: ${(p) => p.fstyle || 'normal'};
  font-weight: ${(p) => p.fw || 500};
  line-height: ${(p) => p.lh || 150}%;
  text-align: ${(p) => p?.textAlign};
  letter-spacing: ${(p) => p?.letterSpacing}px;
  font-family: ${(p) => p.fontFamily || 'Azeret Mono'};
`;

export const ScItem = styled(Button)`
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 4px;
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.87);
  font-family: 'Azeret Mono';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 150%; */
  width: 100%;
  > div {
    width: 100%;
    > div {
      > img {
        margin-right: 12px;
      }
    }
  }
`;

export const TitleWrapper = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: #ffffff;
  display: flex;
  justify-content: center;
`;

export const ScRight = styled.div<{ dFlex?: boolean; flexEnd?: boolean }>`
  ${(props) =>
    props.dFlex &&
    css`
      display: flex;
      justify-content: center;
    `}
  ${(props) =>
    props.flexEnd &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
  .wallet-adapter-button-trigger {
    display: flex;
    padding: 10px 16px 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #e1b248;
    color: #111111;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 22.5px */
    letter-spacing: -0.45px;
  }
`;

export const ColPostContent = styled(Col)`
  margin-top: 24px;
`;
export const BorderBottomWrapper = styled.div<{ mt?: number }>`
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  margin-top: ${(p) => p.mt || 0}px;
`;

export const MainWrapper = styled.div<{ mt?: number }>`
  display: flex;
  padding: 32px 32px 40px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #303239;
  background: #1d1c1f;
  margin-top: ${(p) => p.mt || 0}px;
`;

export const ScBtnSubmit = styled(Button) <{
  bgColor?: string;
  wAuto?: boolean;
}>`
  display: flex;
  padding: 10px 16px 11px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: ${(p) => p.bgColor || '#e1b248'};
  width: ${(p) => (p.wAuto ? 'auto' : '100%')};
  height: 100%;
  margin-top: 8px;
  .ant-btn-loading-icon {
    color: #fff;
  }
`;

export const ScBtnApprove = styled(Button) <{ bgColor?: string }>`
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: ${(p) => p.bgColor || '#027600'};
  margin-right: 12px;
`;

export const ScRowWarning = styled(Row)`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1.5px solid #b06d08;
  background: #643d02;
  margin-top: 12px;
  margin-bottom: 8px;
`;
