import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MyWalletProvider } from './roots/WalletProvider';
import { LandingPage } from './pages/landing-page';
import '@solana/wallet-adapter-react-ui/styles.css';
import { CreateProfile } from 'pages/create-profile';
import { CreatePostPage } from 'pages/create-post';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/create-profile',
    element: <CreateProfile />,
  },
  {
    path: '/create-post',
    element: <CreatePostPage />,
  },
  // {
  //   path: '/profile',
  //   element: <ProfilePage />,
  // },
  // {
  //   path: '/post',
  //   element: <PostPage />,
  // },
  // {
  //   path: '/post-detail/:id',
  //   element: <PostDetail />,
  // },
]);

const App = () => {
  return (
    <MyWalletProvider>
      <RouterProvider router={router} />
    </MyWalletProvider>
  );
};
export default App;
