import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { NavBar } from './NavBar';
import { Container } from './Grid';
import { Button } from './Button';
import { TwitterOutlined, SendOutlined } from '@ant-design/icons';

import '../css/normalize.css';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
  }

  body {
    font-family: "Azeret Mono";
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
     background-color: #0f0e13;
    color: white;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Azeret Mono";
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  a {
    text-decoration: none;
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ScMain = styled.div`
  background: #0f0e13;
  margin-top: 64px;
`;

const ScFooter = styled.footer``;
const ScFooterBody = styled.div`
  display: flex;
  margin-bottom: 2rem;
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 1rem;
  position: relative;
`;
const ScFooterFrog = styled.div`
  width: 48px;
  height: 24px;
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: #0f0e13;

  img {
    width: 24px;
  }
`;
const ScFooterLeft = styled.div`
  flex: 1;
`;
const ScFooterRight = styled.div`
  flex: none;
  text-align: right;
`;

export const Layout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <>
      <GlobalStyle />
      <NavBar />

      <ScMain>{children}</ScMain>

      <ScFooter>
        <Container>
          <ScFooterBody>
            <ScFooterFrog>
              <img src="/images/frog_logo.png" alt="frog" />
            </ScFooterFrog>
            <ScFooterLeft>Gigglr.xyz © 2024. All rights reserved</ScFooterLeft>
            <ScFooterRight>
              <Button
                type="link"
                target="_blank"
                href="https://twitter.com/gigglr_xyz"
                icon={<TwitterOutlined rev={{}} />}
              />
              <Button
                type="link"
                target="_blank"
                href="https://t.me/solareum_hq"
                icon={<SendOutlined rev={{}} />}
              />
            </ScFooterRight>
          </ScFooterBody>
        </Container>
      </ScFooter>
    </>
  );
};
