import React from 'react';
import { Field, FieldProps, FastField, FastFieldProps } from 'formik';
import { Form, Input as AntdInput } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { InputProps } from 'antd/lib/input';
import { ScLabel } from './CommonCom';
import styled from 'styled-components';

type Props = InputProps & {
    name: string;
    fast?: boolean;
    FormItemProps?: FormItemProps;
    showCountLength?: boolean;
    maxLength?: number;
};

const AntdInputWrapper = styled(AntdInput) <{ mb?: number }>`
  border-radius: 8px;
  border: 1.5px solid #303239;
  background: rgba(255, 255, 255, 0.12);
  margin-top: 8px;
  margin-bottom: ${(p) => p.mb || 16}px;
  height: 44px;
`;

export const FormikInput: React.FC<Props> = ({
    name,
    fast,
    onChange,
    showCountLength = false,
    maxLength = 280,
    FormItemProps = {},
    ...rest
}) => {
    const FieldComponent = fast ? FastField : Field;

    return (
        <>
            {rest?.title && (
                <ScLabel
                    fs={13}
                    color="#FFFFFF"
                    fw={500}
                    letterSpacing={-0.39}
                    lh={150}
                >
                    {rest?.title}
                </ScLabel>
            )}
            <FieldComponent name={name}>
                {({ field, meta, form }: FieldProps | FastFieldProps) => (
                    <Form.Item
                        name={name}
                        validateStatus={meta.touched && meta.error ? 'error' : 'success'}
                        help={meta.touched && meta.error}
                        {...FormItemProps}
                    >
                        <AntdInputWrapper
                            {...field}
                            {...rest}
                            onChange={(e) => {
                                onChange?.(e); // onChange passed into the field
                                field.onChange(e);
                                if (!meta.touched) form.setFieldTouched(name, true);
                            }}
                        />
                        {showCountLength && (
                            <ScLabel
                                mt={-12}
                                fs={11}
                                fw={600}
                                letterSpacing={-0.33}
                                lh={150}
                                color={meta.error ? '#ff4d4f' : 'rgba(255, 255, 255, 0.24)'}
                            >{`${field?.value?.length}/${maxLength}`}</ScLabel>
                        )}
                    </Form.Item>
                )}
            </FieldComponent>
        </>
    );
};
