import { Container, ScSection } from '../components/Grid';
import { Layout } from 'components';

import { PrimaryButton, Button } from 'components/Button';

import styled from 'styled-components';

const ScContent = styled.div`
  margin-bottom: 4rem;

  @media screen and (min-width: 960px) {
    display: flex;
  }
`;

const ScContentLeft = styled.div`
  flex: 2;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media screen and (min-width: 960px) {
    flex-direction: column;
  }
`;
const ScContentRight = styled.div`
  flex: 1;
  display: none;

  img {
    max-width: 100%;
  }

  @media screen and (min-width: 960px) {
    display: initial;
  }
`;
const ScImgBox = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const ScImgBoxItem = styled.div`
  flex: 1;

  img {
    max-width: 100%;
  }
`;

const ScIntro = styled.p`
  font-size: 16px;
  line-height: 1.4;

  img {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 960px) {
    font-size: 18px;

    img {
      display: none;
    }
  }
`;

const ScH2 = styled.h2`
  line-height: 1.2;
`;
const ScH3 = styled.h3`
  text-align: center;
  line-height: 1.2;
`;

const ScSubtitle = styled.small`
  color: #e1b248;
  text-transform: uppercase;
  display: block;
`;

const ScHelper = styled.p`
  font-size: 14px;
  margin-bottom: 2em;

  @media screen and (min-width: 960px) {
    text-align: center;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ScFeature = styled.div`
  @media screen and (min-width: 960px) {
    display: flex;
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;
const ScFeatureItem = styled.div`
  position: relative;
  margin-bottom: 1rem;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
  }

  @media screen and (min-width: 960px) {
    flex: 1;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
const ScFeatureItemBlock = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: linear-gradient(
    180deg,
    rgba(29, 28, 31, 0) 0%,
    rgba(29, 28, 31, 0.87) 24.5%,
    #1d1c1f 96.5%
  );
`;
const ScFeatureItemHeader = styled.h4`
  font-size: 24px;
  margin-bottom: 0.5em;
`;
const ScFeatureItemText = styled.p`
  font-size: 14px;
`;

const ScPartnerList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ScPartnerItem = styled.div`
  width: 120px;

  img {
    width: 100%;
  }
`;

export const LandingPage = () => {
  return (
    <Layout>
      <Container style={{ marginTop: 128 }}>
        <ScSection>
          <ScContent>
            <ScContentLeft>
              <div>
                <ScH2>
                  Get ready to cook up memes that make&nbsp;people&nbsp;LOL!
                </ScH2>
                <p>
                  Gigglr.xyz - a lively social hub where memes are more than
                  just hilarious pics, this is your gateway to creativity and
                  cash! Let loose your imagination, whip up captivating content,
                  and reap rewards for your viral masterpieces.
                </p>
                <PrimaryButton
                  size="large"
                  onClick={() => {
                    alert(
                      'It will be ready shortly. Thank you for your patience.'
                    );
                  }}
                >
                  Become a Gigglr member
                </PrimaryButton>
              </div>

              <ScImgBox>
                <ScImgBoxItem style={{ marginRight: '1rem' }}>
                  <img src="/images/landing/meme_02.png" alt="frog" />
                </ScImgBoxItem>
                <ScImgBoxItem>
                  <img src="/images/landing/meme_03.png" alt="pika" />
                </ScImgBoxItem>
              </ScImgBox>
            </ScContentLeft>
            <ScContentRight>
              <img src="/images/landing/meme_01.png" alt="meme world" />
            </ScContentRight>
          </ScContent>

          <ScIntro>
            <img src="/images/landing/meme_04.jpg" alt="meme world" />
            Prepare to transform your ideas into viral memes! Dive into our meme
            creation platform and become a member of the wackiest community on
            the web. Start crafting, sharing, cashing in, and chuckling away
            today!
          </ScIntro>
        </ScSection>

        <ScSection>
          <ScSubtitle style={{ textAlign: 'center' }}>
            [Are you ready?]
          </ScSubtitle>
          <ScH3>Get started</ScH3>
          <ScHelper>
            Hey there! Ready to join the fun? Before diving in, make sure you've
            got Phantom Wallet installed and some XSB tokens in your pocket.
            They're your golden tickets to board this exhilarating ride!
          </ScHelper>

          <ScFeature>
            <ScFeatureItem>
              <img src="/images/landing/phantom.png" alt="Phantom Wallet" />
              <ScFeatureItemBlock>
                <ScFeatureItemHeader>Phantom Wallet</ScFeatureItemHeader>
                <ScFeatureItemText>
                  The crypto wallet that’ll take you places. Powerful tools make
                  for everyone. Controled by you, secure by us.
                </ScFeatureItemText>
                <Button
                  target="_blank"
                  href="https://chromewebstore.google.com/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa"
                >
                  Try Phantom Wallet
                </Button>
              </ScFeatureItemBlock>
            </ScFeatureItem>

            <ScFeatureItem>
              <img src="/images/landing/solana.png" alt="Solana" />
              <ScFeatureItemBlock>
                <ScFeatureItemHeader>XSB Token</ScFeatureItemHeader>
                <ScFeatureItemText>
                  Powered by cutting-edge technology. XSB opens doors to
                  seamless tx, rewarding experiences, and exciting
                  opportunities.
                </ScFeatureItemText>
                <Button
                  style={{ marginRight: '1rem' }}
                  target="_blank"
                  href="https://jup.ag/swap/SOL-XSB"
                >
                  Buy XSB now
                </Button>
                <Button
                  type="link"
                  target="_blank"
                  href="https://solareum.app/blog/solareum-white-paper/"
                >
                  More about XSB
                </Button>
              </ScFeatureItemBlock>
            </ScFeatureItem>
          </ScFeature>
        </ScSection>

        <ScSection style={{ marginBottom: '16rem' }}>
          <ScSubtitle style={{ textAlign: 'center' }}>
            [we're all onboard]
          </ScSubtitle>
          <ScH3>Our Partners</ScH3>
          <ScHelper>
            Get to know our amazing partners! They're the secret sauce behind
            our success, adding unique flavors and expertise to our journey.
            From tech wizards to creative geniuses, our partners are the driving
            force propelling us toward greatness.
          </ScHelper>

          <ScPartnerList>
            <ScPartnerItem>
              <Button
                type="link"
                target="_blank"
                href="https://bozocollective.com/"
              >
                <img src="/images/landing/p_bozo.png" alt="bozo" />
              </Button>
            </ScPartnerItem>
            <ScPartnerItem>
              <Button
                type="link"
                target="_blank"
                href="https://www.theprimes.io/"
              >
                <img src="/images/landing/p_primes.png" alt="the primes" />
              </Button>
            </ScPartnerItem>
            <ScPartnerItem>
              <Button type="link" target="_blank" href="https://solareum.app/">
                <img src="/images/landing/p_solareum.png" alt="Solareum" />
              </Button>
            </ScPartnerItem>
          </ScPartnerList>
        </ScSection>
      </Container>
    </Layout>
  );
};
