import { Col, Row } from 'antd';
import { ScLabel } from './CommonCom';
import styled from 'styled-components';

const TimeLine = styled(Row)`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const CircleWrapper = styled(Col) <{ active?: boolean }>`
  background-color: ${(p) =>
        p.active ? '#1F57E7' : 'rgba(255, 255, 255, 0.12)'};
  border-radius: 12px;
  width: 20px;
  height: 20px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  > img {
    width: 100%;
    height: 100%;
  }
`;

const LineStyled = styled(Col) <{ active?: boolean }>`
  background-color: ${(p) =>
        p.active ? '#1F57E7' : 'rgba(255, 255, 255, 0.12)'};
  height: 4px;
  flex: 1;
`;

export const StepsCommon = ({ current = 0 }: { current: number }) => {
    console.log("🚀 ~ StepsCommon ~ current:", current)
    return (
        <Row gutter={8} align={'middle'} style={{ width: '100%', marginBottom: 8 }}>
            <Col sm={24} md={12}>
                <Row style={{ flexDirection: 'row', width: '100%' }} align={'middle'}>
                    <ScLabel
                        fs={24}
                        color={current >= 1 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.52)'}
                        fw={600}
                        lh={150}
                        letterSpacing={-0.72}
                        mr={9}
                    >01</ScLabel>
                    <ScLabel
                        style={{
                            maxWidth: 110,
                            height: 'auto',
                        }}
                        fs={11}
                        color={current >= 1 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.52)'}
                        fw={700}
                        lh={125}
                        letterSpacing={-0.33}
                    >
                        Upload Image & Approve as NFT
                    </ScLabel>
                </Row>
                <TimeLine>
                    <CircleWrapper active={current >= 1}>
                        <img
                            src={current >= 1 ? '/icons/checked.png' : '/icons/check.png'}
                            alt=""
                        />
                    </CircleWrapper>
                    <LineStyled active={current >= 1} />
                </TimeLine>
            </Col>
            <Col sm={24} md={12}>
                <Row style={{ flexDirection: 'row', width: '100%' }} align={'middle'}>
                    <ScLabel
                        fs={24}
                        color={current >= 2 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.52)'}
                        fw={600}
                        lh={150}
                        letterSpacing={-0.72}
                        mr={9}
                    >02</ScLabel>
                    <ScLabel
                        style={{
                            maxWidth: 110,
                            height: 'auto',
                        }}
                        fs={11}
                        color={current >= 1 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.52)'}
                        fw={700}
                        lh={125}
                        letterSpacing={-0.33}
                    >
                        Create Content & Publish
                    </ScLabel>
                </Row>
                <TimeLine>
                    <CircleWrapper active={current >= 2}>
                        <img
                            src={current >= 2 ? '/icons/checked.png' : '/icons/check.png'}
                            alt=""
                        />
                    </CircleWrapper>
                    <LineStyled active={current >= 2} />
                </TimeLine>
            </Col>
        </Row>
    );
};
