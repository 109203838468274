//@ts-nocheck
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    FormikInput,
    MainWrapper,
    ScBtnApprove,
    ScBtnSubmit,
    ScLabel,
    ScRowWarning,
    UploadFileCommon,
} from 'components';
import { useWallet } from '@solana/wallet-adapter-react';
import { walletAdapterIdentity } from '@metaplex-foundation/js';
import { web3 } from '@coral-xyz/anchor';
import * as anchor from '@coral-xyz/anchor';
import { ASSOCIATED_PROGRAM_ID } from '@coral-xyz/anchor/dist/cjs/utils/token';
import { PROGRAM_ADDRESS, XSB_MINT_ADDRESS } from 'consts';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { metaplex, useNFTStorage, useProgram } from 'hooks';
import { IFormProfile } from 'utils/types';
import { profileSchema, validateImage } from 'utils';
import { MainLayout } from 'components/MainLayout';
import { Formik, Form } from 'formik';
import { Button, Col, Divider, Row } from 'antd';
import { ScSuccessWrapper } from './create-post';
import { useNavigate } from 'react-router-dom';

export const CreateProfile = () => {
    const wallet = useWallet();
    const { uploadNft, nftList } = useNFTStorage();
    const { program } = useProgram();
    const uploadRef = useRef();
    const navigate = useNavigate()


    const [loading, setLoading] = useState<boolean>(true);
    const [loadingNFT, setLoadingNFT] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>(false);
    const [fileUpload, setFileUpload] = useState<any[]>([]);

    useEffect(() => {
        if (!wallet.publicKey) {
            return;
        }
        metaplex.use(walletAdapterIdentity(wallet));
    }, [wallet]);

    const minNFT = async () => {
        setLoadingNFT(true);
        try {
            if (!fileUpload) {
                setErrorFile('Please select file');
                setLoadingNFT(false);
                return;
            }

            let valid = validateImage(fileUpload?.[0], (error) => {
                setErrorFile(error);
                setLoadingNFT(false);
            });

            if (valid) {
                const result = await uploadNft(fileUpload?.[0], '', {
                    description: 'mollit qui incididunt Lorem.',
                    attributes: [],
                    source: 'https://gigglr.xyz',
                    dataVersion: '1.0.0',
                    comments: [
                        {
                            user: 'tamph',
                            content:
                                'Ad esse cupidatat mollit Lorem. Labore in adipisicing ipsum enim esse incididunt irure sint velit id. Mollit labore cupidatat sint veniam elit exercitation culpa eu excepteur irure irure sunt.',
                            date: new Date(),
                        },
                    ],
                });
                if (result) {
                    setLoadingNFT(false);
                    setLoading(false);
                }
            }
        } catch (error) {
            setLoadingNFT(false);
        }
    };

    const createProfile = useCallback(
        async (values: IFormProfile, setSubmitting?: () => void) => {
            try {
                let nft = nftList?.[0];
                console.log('🚀 ~ nft:', nft);
                const [settingAddress] = web3.PublicKey.findProgramAddressSync(
                    [anchor.utils.bytes.utf8.encode('SETTING')],
                    PROGRAM_ADDRESS
                );

                const [programXsbTokenAccount] = web3.PublicKey.findProgramAddressSync(
                    [
                        settingAddress.toBuffer(),
                        TOKEN_PROGRAM_ID.toBuffer(),
                        XSB_MINT_ADDRESS.toBuffer(),
                    ],
                    ASSOCIATED_PROGRAM_ID
                );

                const [programNativeAccount] = web3.PublicKey.findProgramAddressSync(
                    [settingAddress.toBuffer()],
                    PROGRAM_ADDRESS
                );
                const [userXsbTokenAccount] = web3.PublicKey.findProgramAddressSync(
                    [
                        wallet?.publicKey?.toBuffer(),
                        TOKEN_PROGRAM_ID.toBuffer(),
                        XSB_MINT_ADDRESS.toBuffer(),
                    ],
                    ASSOCIATED_PROGRAM_ID
                );
                const [userProfileAddress] = web3.PublicKey.findProgramAddressSync(
                    [wallet?.publicKey?.toBuffer()],
                    PROGRAM_ADDRESS
                );
                const [nftTokenAddress] = web3.PublicKey.findProgramAddressSync(
                    [
                        wallet?.publicKey?.toBuffer(),
                        TOKEN_PROGRAM_ID.toBuffer(),
                        nft.mint.address.toBuffer(),
                    ],
                    ASSOCIATED_PROGRAM_ID
                );

                const tx = await program?.methods
                    .createProfile(`${values?.displayName}`)
                    .accounts({
                        setting: settingAddress,
                        programNativeAccount,
                        mint: XSB_MINT_ADDRESS,
                        programTokenAccount: programXsbTokenAccount,
                        user: wallet.publicKey,
                        userTokenAccount: userXsbTokenAccount,
                        profile: userProfileAddress,
                        systemProgram: anchor.web3.SystemProgram.programId,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        associatedTokenProgram: ASSOCIATED_PROGRAM_ID,
                        nftToken: nftTokenAddress,
                        nftMetadata: nft.metadataAddress,
                    })
                    .rpc();

                if (tx) {
                    setIsFinished(true);
                }
                console.log('tx', tx);
                setLoading(false);
            } catch (error) {
                console.log('🚀 ~ error:', error);
                setSubmitting(false);
                setLoading(false);
            }
        },
        [nftList, program?.methods, wallet.publicKey]
    );

    const onGetFile = (file) => {
        setFileUpload(file);
    };

    return (
        <MainLayout>
            {isFinished ? (
                <ScSuccessWrapper>
                    <img src="/icons/success.png" alt="" />
                    <ScLabel
                        color="#FFFFFF"
                        fs={28}
                        fw={600}
                        lh={150}
                        letterSpacing={-0.84}
                        mt={24}
                    >
                        Profile Success
                    </ScLabel>
                    <ScLabel
                        color="#FFFFFF"
                        fs={15}
                        fw={600}
                        lh={150}
                        letterSpacing={-0.45}
                    >
                        Your profile is successfully created. Create your's first post now.
                    </ScLabel>
                    <Row>
                        <Button type="link" onClick={() => navigate('/create-post')}>
                            Create Post
                        </Button>
                    </Row>
                </ScSuccessWrapper>
            ) : (
                <>
                    <ScLabel
                        color="#FFFFFF"
                        mt={61}
                        fs={28}
                        fw={600}
                        lh={150}
                        letterSpacing={-0.84}
                        mb={24}
                    >
                        Create profile
                    </ScLabel>
                    <MainWrapper mt={24}>
                        <Formik
                            validationSchema={profileSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                createProfile(values, setSubmitting);
                            }}
                            initialValues={{
                                displayName: '',
                                userName: '',
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form style={{ width: '100%' }}>
                                    <Row align={'middle'}>
                                        <UploadFileCommon onGetFile={onGetFile} ref={uploadRef} />
                                        <Col
                                            style={{
                                                marginLeft: 24,
                                            }}
                                        >
                                            <Row style={{ flexDirection: 'row', marginBottom: 8 }}>
                                                <ScBtnApprove
                                                    disabled={!fileUpload?.length}
                                                    onClick={() => minNFT()}
                                                    loading={loadingNFT}
                                                >
                                                    <ScLabel
                                                        color={'#FFFFFF'}
                                                        fs={15}
                                                        fw={500}
                                                        lh={150}
                                                        letterSpacing={-0.45}
                                                    >
                                                        Approve Avatar as NFT
                                                    </ScLabel>
                                                </ScBtnApprove>
                                                <ScBtnApprove>
                                                    <ScLabel
                                                        color={'#FFFFFF'}
                                                        fs={15}
                                                        fw={500}
                                                        lh={150}
                                                        letterSpacing={-0.45}
                                                    >
                                                        Select Image
                                                    </ScLabel>
                                                    <img src="/icons/arrow-down.png" alt="" />
                                                </ScBtnApprove>
                                            </Row>
                                            <ScLabel
                                                letterSpacing={-0.39}
                                                color="rgba(190, 190, 190, 0.87)"
                                            >
                                                Support NFT,JPG,JPEG or PNG file.
                                            </ScLabel>
                                        </Col>
                                    </Row>
                                    <ScRowWarning>
                                        <ScLabel letterSpacing={-0.39} color="#FFFFFF" lh={150}>
                                            Each avatar is an NFT - kindly approve its use before
                                            setting it as your profile picture
                                        </ScLabel>
                                    </ScRowWarning>
                                    <Divider style={{ backgroundColor: '#303239' }} />
                                    <FormikInput
                                        name="displayName"
                                        placeholder="Name to display on your Profile"
                                        title="Display name"
                                    />
                                    <FormikInput
                                        name="userName"
                                        placeholder="URL"
                                        title="Profile URL"
                                        prefix="https://gigglr.xyz/"
                                    />
                                    <ScBtnSubmit
                                        type="primary"
                                        htmlType="submit"
                                        disabled={isSubmitting || loading}
                                    >
                                        <ScLabel
                                            color={loading ? '#FFFFFF' : '#111111'}
                                            fs={15}
                                            fw={500}
                                            lh={150}
                                            letterSpacing={-0.45}
                                        >
                                            Create profile
                                        </ScLabel>
                                    </ScBtnSubmit>
                                </Form>
                            )}
                        </Formik>
                    </MainWrapper>
                </>
            )}
        </MainLayout>
    );
};
