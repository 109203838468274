import { Avatar, Button, Col, Row } from 'antd';
import { BorderBottomWrapper, ScLabel } from './CommonCom';
import styled from 'styled-components';
import { TAGS_LIST } from 'utils';

const ColWrapper = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ScImage = styled.img`
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
`;
const ScButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  img {
    width: 24px;
    height: 24px;
  }
`;

const ScTags = styled(Row)`
  margin-bottom: 12px;
  > div {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    margin-right: 8px;
  }
`;

const ScAction = styled(Row)`
  margin-bottom: 20px;
`;

const ScColAction = styled(Col) <{ mr?: number }>`
  margin-right: ${(p) => p.mr || 0}px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IPostProps {
    author?: string;
    createdAd?: string;
    item?: any;
}

export const PostItem = ({
    author = 'Anonymous',
    createdAd = '7h',
    item,
}: IPostProps) => {
    return (
        <BorderBottomWrapper mt={24}>
            <Row
                justify={'space-between'}
                align={'middle'}
                style={{ marginBottom: 16 }}
            >
                <Avatar src={'/images/avatar-default.png'} size={40} />
                <Col style={{ marginLeft: 12 }} flex={1}>
                    <ScLabel
                        fs={16}
                        fw={600}
                        lh={150}
                        letterSpacing={-0.48}
                        color="#FFFFFF"
                    >
                        {author}
                    </ScLabel>
                    <ScLabel
                        fs={12}
                        fw={500}
                        lh={150}
                        letterSpacing={-0.36}
                        color="rgba(190, 190, 190, 0.52)"
                    >
                        {createdAd}
                    </ScLabel>
                </Col>
                <ColWrapper span={4}>
                    <ScButton onClick={() => { }}>
                        <img src={'/icons/more.png'} alt="" />
                    </ScButton>
                    <ScButton onClick={() => { }}>
                        <img src={'/icons/close.png'} alt="" />
                    </ScButton>
                </ColWrapper>
            </Row>
            <Row>
                <ScLabel mb={8} fs={18} color="#FFFFFF" fw={700}>
                    {item?.json?.postContent}
                </ScLabel>
                <ScImage src={item?.json?.image} alt="" />
            </Row>
            <ScTags>
                {TAGS_LIST.map((tag, index) => (
                    <Col key={tag.id}>
                        <ScLabel
                            color="rgba(255, 255, 255, 0.87)"
                            fw={500}
                            letterSpacing={-0.39}
                        >
                            {tag.name}
                        </ScLabel>
                    </Col>
                ))}
            </ScTags>
            <ScAction>
                <ScColAction>
                    <ScButton onClick={() => { }}>
                        <img src={'/icons/arrow-up.png'} alt="" />
                    </ScButton>
                    <ScLabel ml={8} mr={8} fs={15} fw={600} color='rgba(255, 255, 255, 0.52)'>
                        1.6K
                    </ScLabel>
                    <ScButton onClick={() => { }}>
                        <img src={'/icons/arrow-down.png'} alt="" />
                    </ScButton>
                </ScColAction>
                <ScColAction mr={40} flex={1}>
                    <ScButton onClick={() => { }}>
                        <img src={'/icons/coffee.png'} alt="" />
                    </ScButton>
                    <ScLabel ml={8} fs={15} fw={600} color='rgba(255, 255, 255, 0.52)'>Support</ScLabel>
                </ScColAction>
                <ScColAction>
                    <ScButton onClick={() => { }}>
                        <img src={'/icons/send.png'} alt="" />
                        <ScLabel ml={8} fs={15} fw={600} color='rgba(255, 255, 255, 0.52)'>Share</ScLabel>
                    </ScButton>
                </ScColAction>
            </ScAction>
        </BorderBottomWrapper>
    );
};
