import { Metaplex } from '@metaplex-foundation/js';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection } from '@solana/web3.js';
import { NFT_STORAGE_TOKEN } from 'consts';
import { NFTStorage } from 'nft.storage';
import { useEffect, useState } from 'react';

const connection = new Connection(
  'https://rpc.ankr.com/solana_devnet/9b5f1c65250aec76490ae2deb79e804479c44e92f7c786201ff987974de23676',
  {
    wsEndpoint:
      'wss://rpc.ankr.com/solana_devnet/ws/9b5f1c65250aec76490ae2deb79e804479c44e92f7c786201ff987974de23676',
  }
  // 'wss://rpc.ankr.com/solana_devnet/ws/9b5f1c65250aec76490ae2deb79e804479c44e92f7c786201ff987974de23676'
  // 'https://rpc.ankr.com/solana/9b5f1c65250aec76490ae2deb79e804479c44e92f7c786201ff987974de23676'
);
export const metaplex = new Metaplex(connection);

const metadataDefault = {
  description: 'mollit qui incididunt Lorem.',
  attributes: [],
  properties: [],
  external_url: 'https://gigglr.xyz',
  // animation_url: '',
  // will support md anytime soon
  dataVersion: '1.0.0',
  comments: [
    {
      user: 'tamph',
      content:
        'Ad esse cupidatat mollit Lorem. Labore in adipisicing ipsum enim esse incididunt irure sint velit id. Mollit labore cupidatat sint veniam elit exercitation culpa eu excepteur irure irure sunt.',
      date: 123,
    },
  ],
};

export const useNFTStorage = () => {
  const wallet = useWallet();
  const [nftList, setNftList] = useState<any[]>([]);

  useEffect(() => {
    const getNFTList = async () => {
      if (!wallet.publicKey) {
        setNftList([]);
        return;
      }

      const refNfts = await metaplex
        .nfts()
        .findAllByOwner({ owner: wallet.publicKey });

      const nfts = await Promise.all(
        refNfts.map((i) =>
          metaplex
            .nfts()
            .load({ metadata: i as any })
            .catch((err) => {
              console.log('err', i, err);
            })
        )
      );
      setNftList(nfts);
    };
    getNFTList();
  }, [wallet]);

  const uploadNft = async (
    files: any,
    postContent: string,
    meta: any = metadataDefault
  ) => {
    let file = files?.originFileObj;
    if (!file) return;

    const client = new NFTStorage({ token: NFT_STORAGE_TOKEN });
    console.log('file', file, client.endpoint);
    const imgCid = await client.storeBlob(new Blob([file]));
    const imgUrl = `https://ipfs.io/ipfs/${imgCid}`;
    console.log('imgUrl', imgUrl);

    const name = 'GigglrXyz';
    const symbol = 'GIG';

    const metadataJson = {
      ...meta,
      name,
      symbol,
      postContent,
      image: imgUrl,
      // animation_url: '',
      // will support md anytime soon
      dataVersion: '1.0.0',
    };

    const metadata = new Blob([JSON.stringify(metadataJson)], {
      type: 'application/json',
    });
    const metadataCid = await client.storeBlob(metadata);
    const metadataUrl = `https://ipfs.io/ipfs/${metadataCid}`;
    console.log('metadataUrl', metadataUrl);

    const tx = await metaplex
      .nfts()
      .create(
        {
          name,
          symbol,
          uri: metadataUrl,
          sellerFeeBasisPoints: 700, // Represents 7.0%
          isMutable: false,
        },
        { commitment: 'finalized' }
      )
      .catch((err) => {
        console.log('create new NFT', err);
      });

    if (tx) {
      // call back get nftList after create
      if (!wallet.publicKey) {
        return;
      }
      const refNfts = await metaplex
        .nfts()
        .findAllByOwner({ owner: wallet.publicKey });

      const nfts = await Promise.all(
        refNfts.map((i) =>
          metaplex
            .nfts()
            .load({ metadata: i as any })
            .catch((err) => {
              console.log('err', i, err);
            })
        )
      );
      setNftList(nfts);
    }

    return tx;
  };

  return {
    uploadNft,
    nftList,
  };
};
