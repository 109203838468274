//@ts-nocheck
import { GetProp, Modal, Upload, UploadProps } from 'antd';
import { UploadFile } from 'antd/lib';
import { useRef, useState } from 'react';
import styled from 'styled-components';

const ScUpload = styled.div`
  .ant-upload,
  .ant-upload-select {
    border: none !important;
  }

  .ant-upload-list-item-container {
    width: 88px!important;
    height: 88px!important;
  }
`;

export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const UploadFileCommon = (props) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const uploadRef = useRef(null)

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
        );
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        props?.onGetFile?.(newFileList);
        setFileList(newFileList);
    };


    return (
        <ScUpload>
            <Upload
                action=""
                listType="picture-circle"
                onPreview={handlePreview}
                onChange={handleChange}
                multiple={false}
                accept=".jpg, .jpeg, .png"
                ref={(el) => {
                    uploadRef.current = el;
                }}
                {...props}
            >
                {fileList.length >= 1 ? null : (
                    <img src={"/images/ava_big_default.png"} alt="" />
                )}
            </Upload>

            <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </ScUpload>
    );
}
