import React from 'react';
import { NavBar } from './NavBar';
import '../css/normalize.css';
import styled from 'styled-components';

const MainWrapper = styled.div`
  width: 593px;
  background-color: #0f0e13;
`;
const CenterLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  background-color: #0f0e13;
`;
const MainWrapperStyled = styled.main`
  width: 100vw;
  height: 100vh;
  background-color: #0f0e13;
`;

interface IMainLayout extends React.PropsWithChildren { }

export const MainLayout = ({ children }: IMainLayout) => {

  return (
    <MainWrapperStyled>
      <NavBar />
      <CenterLayout>
        <MainWrapper>
          {children}
        </MainWrapper>
      </CenterLayout>
    </MainWrapperStyled>
  );
};
